import axios, { AxiosInstance } from 'axios';
import resolveEnv, { Env } from '../../util/env';

const env = resolveEnv();

let baseURL =
  env === Env.Production
    ? 'https://backend.arkivet.app.iterate.no/'
    : env === Env.Development
    ? 'https://backend.arkivet.test.iterate.no/'
    : 'http://localhost:4000';

export const arkivetApi: AxiosInstance = axios.create({
  baseURL,
  withCredentials: true,
});

//Redirect to login when 403
arkivetApi.interceptors.response.use(
  response => response,
  error => {
    if (error.response.status === 403) {
      window.location.href = '/login?returnTo=' + window.location.pathname;
    }
  }
);
