import { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import { CustomDropdown } from '../../../components/input/CustomDropdown';
import { CustomText } from '../../../components/input/CustomText';
import { StyledButton } from '../../../components/styled/StyledButton';
import { StyledPanel } from '../../../components/styled/StyledPanel';
import { useSession } from '../../../services/api/useAuth';
import { useSendEmail } from '../../../services/api/useMail';
import { useGetMessages } from '../../../services/api/useMessages';
import { ProjectType, useUpdateProject } from '../../../services/api/useProject';
import { useGetUserById } from '../../../services/api/useUser';
import { formatDate } from '../../../util/date';
import resolveEnv from '../../../util/env';
import { StatusIndicator } from './StatusIndicator';

const PROJECT_LEAD_FOLLOWUP_STATUS = [
  {
    value: 'no',
    text: 'Nei',
  },
  {
    value: 'email',
    text: 'Epost',
  },
  {
    value: 'phone',
    text: 'Telefon',
  },
  {
    value: 'sms',
    text: 'Sms',
  },
  {
    value: 'rejected',
    text: 'Avvist',
  },
  {
    value: 'accepted',
    text: 'Akseptert',
  },
];

const emailNudgeTemplate = `Hei, så hyggelig at du er interessert i å få hjelp til ditt neste prosjekt. Vi trenger litt mer info så skal vi finne en passende designer til deg. \n\nHilsen Arkivet-teamet`;

export const ProjectDetails: FC<{ data: ProjectType }> = ({
  data: {
    address,
    budget,
    description,
    id,
    title,
    status,
    customerEmail,
    customerId,
    postalCode,
    helpType,
    initialMeetingType,
    designerComment,
    emailDefault,
    leadFollowedStatus,
  },
}) => {
  const [designerCommentValue, setDesignerCommentValue] = useState(designerComment || '');
  const [emailValue, setEmailValue] = useState(emailDefault || '');
  const messages = useGetMessages(id);

  const [saving, setSaving] = useState(false);
  const [presetLeadStatusValue, setPresetLeadStatusValue] = useState({ value: '', text: '' });
  const { data } = useGetUserById(customerId);
  const updatedProject = useUpdateProject();
  const sendEmail = useSendEmail();
  const session = useSession();
  const [confirmSendEmail, setConfirmSendEmail] = useState(false);

  useEffect(() => {
    setPresetLeadStatusValue(
      PROJECT_LEAD_FOLLOWUP_STATUS.find(item => item.value === leadFollowedStatus) || { value: '', text: '' }
    );
  }, [leadFollowedStatus]);

  return (
    <StyledProjectDetails>
      <div className="header-area">
        <h1>{title}</h1>
        <StatusIndicator status={status} />
        {status === 'lead' && (
          <a
            href={`${
              resolveEnv() === 'localhost' ? 'http://localhost:3002' : 'https://www.arkivet.co'
            }/admin/project/create?id=${id}${data && data.token ? `&token=${data.token}` : ''}`}
            target="_blank"
            rel="noreferrer"
          >
            Lag prosjekt av leaden
          </a>
        )}
      </div>
      <div className="info">
        <div>
          <Detail title="Id" value={id} />
          <Detail title="Adresse" value={address} />
          <Detail title="Postkode" value={postalCode} />
          <Detail title="Budsjett" value={budget} />
          <Detail title="Valgt produkt" value={helpType} />
          <Detail title="Første møte" value={initialMeetingType} />
        </div>
        <div>
          <Detail title="Kunde mail" value={customerEmail} />
          <Detail title="Kunde navn" value={`${data?.firstName || ''} ${data?.lastName || ''}`} />
          <Detail title="Kunde telefon" value={data?.phone} />
        </div>
        <Detail title="Beskrivelse" value={description} />
      </div>
      <div
        style={{
          marginBottom: 40,
        }}
      >
        <p>Har du kontaktet kunden?</p>
        <CustomDropdown
          presetValue={presetLeadStatusValue}
          label="Lead oppfølging status"
          items={PROJECT_LEAD_FOLLOWUP_STATUS}
          onItemClicked={async leadFollowedStatus => {
            setSaving(true);
            await updatedProject.mutateAsync({
              id,
              leadFollowedStatus,
            });
            setSaving(false);
          }}
        />
      </div>
      <CustomText
        label="Designer kommentar"
        value={designerCommentValue}
        onChange={({ target }) => setDesignerCommentValue(target.value)}
      />
      <StyledButton
        style={{
          width: 'fit-content',
          marginTop: 20,
        }}
        disabled={saving}
        onClick={async () => {
          setSaving(true);
          await updatedProject.mutateAsync({
            id,
            designerComment: designerCommentValue,
          });
          setSaving(false);
        }}
      >
        Oppdater kommentar
      </StyledButton>
      <CustomText
        style={{
          marginTop: 20,
        }}
        label="E-post"
        value={emailValue}
        onChange={({ target }) => {
          setConfirmSendEmail(false);
          return setEmailValue(target.value);
        }}
      />
      {!confirmSendEmail && (
        <StyledButton
          style={{
            width: 'fit-content',
            marginTop: 20,
          }}
          disabled={saving}
          onClick={async () => {
            setConfirmSendEmail(true);
          }}
        >
          Send e-post
        </StyledButton>
      )}
      {confirmSendEmail && (
        <StyledButton
          style={{
            width: 'fit-content',
            marginTop: 20,
          }}
          disabled={saving}
          onClick={async () => {
            setSaving(true);
            await sendEmail.mutateAsync({
              id,
              body: emailValue,
              senderId: session.data?.user?.id,
              chatId: '?',
            });
            setSaving(false);
            setConfirmSendEmail(false);
            setEmailValue('');
          }}
        >
          Bekreft sending til {customerEmail}
        </StyledButton>
      )}
      <StyledButton
        style={{
          width: 'fit-content',
          marginTop: 20,
          marginLeft: 20,
        }}
        disabled={saving}
        onClick={async () => {
          setConfirmSendEmail(false);
          setEmailValue(emailNudgeTemplate);
        }}
      >
        Mal: Nudge ny lead
      </StyledButton>
      <MessageRowWrapper>
        <h2>Meldinger</h2>
        {messages.data?.map((m, i) => {
          return (
            <MessageRow key={i}>
              <div>
                <span>
                  ({m.user.firstName || m.user.userId}) {formatDate(m.updatedAt)}
                </span>
                <p>{m.body}</p>
              </div>
            </MessageRow>
          );
        })}
      </MessageRowWrapper>
    </StyledProjectDetails>
  );
};

const Detail: FC<{ title: string; value?: string }> = ({ title, value }) => {
  return (
    <StyledDetail>
      <h3>{title}</h3>
      <p>{value}</p>
    </StyledDetail>
  );
};
const StyledProjectDetails = styled(StyledPanel)`
  .info {
    display: grid;
    grid-template-columns: 1fr 1fr 2fr;
    gap: 20px;
    div:not(:last-of-type) {
      margin-bottom: 40px;
    }
  }
  .header-area {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
  }
`;

const StyledDetail = styled.div`
  h3 {
    font-size: 16px;
    font-weight: 800;
    margin: 10px 0;
  }
  p {
    margin: 4px 0;
  }
`;

const MessageRowWrapper = styled.div`
  padding-top: 20px;
  padding-bottom: 20px;
`;

const MessageRow = styled.div`
  margin-bottom: 30px;
`;
