import { ReactNode, ComponentType } from 'react';
import { LinearLoader } from '../loader/LinearLoader';
import { StyledTable } from '../styled/StyledTable';

export type TableColumn<TData> = {
  key: string;
  title: string;
  render: (row: TData) => ReactNode;
  sortable?: boolean;
  style?: Record<string, string | number>;
};

export type TableColumns<TData> = TableColumn<TData>[];

export type Sorting = {
  key: string;
  order: 'asc' | 'desc';
};

export type TableProps<TData> = {
  data: TData[] | undefined;
  columns: TableColumn<TData>[];
  sorting?: Sorting;
  onSort?: (sorting: Sorting) => void;
  loading?: boolean;
  fetchMore?: () => void;
  EmptyListComponent?: ComponentType;
};

export const DataTable = <TData extends {}>({
  data,
  columns,
  sorting,
  onSort,
  loading,
  fetchMore,
  EmptyListComponent,
}: TableProps<TData>) => {
  return (
    <StyledTable>
      <thead>
        <tr>
          {columns.map(({ key, title, sortable }) => (
            <th key={key}>
              <span>{title}</span>
              {sortable && sorting && onSort && <SortButton sortKey={key} sorting={sorting} onSort={onSort} />}
            </th>
          ))}
        </tr>
      </thead>

      <tbody>
        {data?.map((row, index) => (
          <tr key={index}>
            {columns.map(({ key, render, style }) => (
              <td style={style} key={key}>
                {render(row)}
              </td>
            ))}
          </tr>
        ))}

        {!loading && data?.length === 0 && EmptyListComponent && (
          <tr>
            <td colSpan={columns.length}>
              <EmptyListComponent />
            </td>
          </tr>
        )}
        {loading && (
          <tr>
            <td colSpan={columns.length}>
              <LinearLoader />
            </td>
          </tr>
        )}
        {!loading && fetchMore && (
          <tr>
            <td colSpan={columns.length}>
              <div>
                <button onClick={fetchMore}>Last mer</button>
              </div>
            </td>
          </tr>
        )}
      </tbody>
    </StyledTable>
  );
};

const SortButton = ({
  sortKey,
  sorting,
  onSort,
  className,
}: {
  sortKey: string;
  sorting: Sorting;
  onSort: (sorting: Sorting) => void;
  className?: string;
}) => {
  return (
    <button
      onClick={() =>
        onSort({
          key: sortKey,
          order: sorting.key === sortKey ? (sorting.order === 'desc' ? 'asc' : 'desc') : 'asc',
        })
      }
    >
      {sorting.key === sortKey ? (sorting.order === 'desc' ? '▲' : '▼') : '○'}
    </button>
  );
};
