import { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { CustomCheckbox } from '../../components/input/CustomCheckbox';
import { CustomInput } from '../../components/input/CustomInput';
import { StyledButton } from '../../components/styled/StyledButton';
import { StyledPanel } from '../../components/styled/StyledPanel';
import { useGetUserById, UserRoleType, UserType, useUpdatedUser } from '../../services/api/useUser';

export const User: FC = () => {
  const { id } = useParams();
  const { data } = useGetUserById(id);
  const updateUser = useUpdatedUser();

  const [user, setUser] = useState<UserType>(data || ({} as UserType));
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const updateUserData = (key: keyof UserType, value: string) => {
    setUser(cs => ({ ...cs, [key]: value }));
  };

  const saveUser = async () => {
    setIsLoading(true);
    await updateUser.mutateAsync(user);
    setIsLoading(false);
  };

  const updateUserRoles = (role: UserRoleType, checked: boolean) => {
    setUser(cs => {
      if (checked) {
        return { ...cs, roles: [...cs.roles, role] };
      }
      return { ...cs, roles: cs.roles.filter(r => r !== role) };
    });
  };

  useEffect(() => {
    setUser(data || ({} as UserType));
  }, [data]);

  const isUserUpdated = JSON.stringify(data) !== JSON.stringify(user);

  return (
    <StyledUserPage>
      <StyledPanel className="info">
        <div className="header">
          <h2>{`${data?.firstName || ''} ${data?.lastName || ''}`}</h2>
          {data?.createdAt && <p>Opprettet: {new Date(data.createdAt).toLocaleDateString()}</p>}
          {data?.updatedAt && <p>Sist oppdatert: {new Date(data.updatedAt).toLocaleDateString()}</p>}
        </div>
        <div className="inputs">
          <CustomInput
            onChange={({ target }) => updateUserData('firstName', target.value)}
            value={user?.firstName || ''}
            label="Fornavn"
          />
          <CustomInput
            onChange={({ target }) => updateUserData('lastName', target.value)}
            value={user?.lastName || ''}
            label="Etternavn"
          />
          <CustomInput
            onChange={({ target }) => updateUserData('email', target.value)}
            value={user?.email || ''}
            label="Email"
          />
          <CustomInput
            onChange={({ target }) => updateUserData('phone', target.value)}
            value={user?.phone || ''}
            label="Telefon"
          />
          <div className="roles">
            <CustomCheckbox
              checked={user?.roles?.some(role => role === 'admin')}
              label="Admin"
              onChange={({ target }) => updateUserRoles('admin', target.checked)}
            />
            <CustomCheckbox
              checked={user?.roles?.some(role => role === 'designer')}
              label="Designer"
              onChange={({ target }) => updateUserRoles('designer', target.checked)}
            />
            <CustomCheckbox
              checked={user?.roles?.some(role => role === 'subscriber')}
              label="Kunde"
              onChange={({ target }) => updateUserRoles('subscriber', target.checked)}
            />
          </div>
        </div>
        <StyledButton onClick={saveUser} disabled={!isUserUpdated}>
          {isUserUpdated ? 'Oppdater' : 'Ingen endringer'}
        </StyledButton>
      </StyledPanel>
    </StyledUserPage>
  );
};

const StyledUserPage = styled.div`
  .info {
    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border: 1px solid black;
      border-radius: 4px;
      padding: 1rem;
      margin-bottom: 1rem;
    }
    .inputs {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 1rem;
      .roles {
        display: flex;
        justify-content: space-between;
      }
    }
  }
`;
