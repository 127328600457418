export const light: ThemeType = {
  name: 'light',
  color: {
    secondary: '#2520EF',
    interactive: '#2520EF',
    muted: '#899598',
  },
};

export type ThemeType = {
  name: string;
  color: {
    secondary: string;
    interactive: string;
    muted: string;
  };
};
