import { AxiosError } from 'axios';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { removeEmptyKeys } from '../../util/object';
import { arkivetApi } from './axios';

export type UserRoleType = 'admin' | 'designer' | 'subscriber';

export type UserType = {
  id: string;
  email: string;
  firstName?: string;
  lastName?: string;
  phone?: string;
  roles: UserRoleType[];
  createdAt: string;
  updatedAt: string;
  token?: string;
};

type ParamType = {
  role?: string;
  id?: string;
  email?: string;
};

export const useGetUsers = (params?: ParamType) => {
  const filteredParams = removeEmptyKeys(params);
  return useQuery<any[], AxiosError>(
    ['all_users', filteredParams],
    async () => {
      const res = await arkivetApi.get<any[]>('/v1/user', {
        params: filteredParams,
      });

      return res.data;
    },
    {
      refetchOnWindowFocus: false,
    }
  );
};

export const useGetUserById = (id?: string) => {
  return useQuery<UserType, AxiosError>(
    ['user', id],
    async () => {
      const { data } = await arkivetApi.get<UserType>(`/v1/user/${id}`);
      return data;
    },
    {
      enabled: !!id,
      refetchOnWindowFocus: false,
    }
  );
};

export type UpdateUserType = {
  id: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  roles?: UserRoleType[];
};

export const useUpdatedUser = () => {
  const queryClient = useQueryClient();

  return useMutation<UpdateUserType, AxiosError, any>(['update_user'], async (data: UpdateUserType) => {
    const filteredData = removeEmptyKeys(data);
    const res = await arkivetApi({
      method: 'PUT',
      url: `/v1/user/${data.id}`,
      data: filteredData,
    });
    queryClient.invalidateQueries('user');
    return res.data;
  });
};
