import { useEffect } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import styled, { ThemeProvider } from 'styled-components';
import { NavigationDrawer } from './components/navigation/NavigationDrawer';
import { Login } from './pages/Login';
import { NoPage } from './pages/NoPage';
import { PasswordChange } from './pages/PasswordChange';
import { Product } from './pages/Products';
import { Project } from './pages/project/Project';
import { Projects } from './pages/project/Projects';
import { SignUp } from './pages/SignUp';
import { User } from './pages/user/User';
import { Users } from './pages/user/Users';
import { useSession } from './services/api/useAuth';
import { useAuthStore } from './services/stores/useAuthStore';
import './styles/global.css';
import { GlobalStyle } from './styles/GlobalStyle';
import { ArkivetThemeProvider, useTheme } from './theme/useTheme';

const queryClient = new QueryClient();
const DEFAULT_PATH = '/projects';
export const App = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <ArkivetThemeProvider>
        <InnerApp />
      </ArkivetThemeProvider>
    </QueryClientProvider>
  );
};

const InnerApp = () => {
  const { isLoggedIn, setUser, setIsLoggedIn, showNavigationDrawer, setShowNavigationDrawer } = useAuthStore();
  const session = useSession();
  const navigate = useNavigate();
  const location = useLocation();
  const { theme } = useTheme();

  useEffect(() => {
    if (isLoggedIn && session.data?.isLoaded && !session.data.user) {
      session.refetch();
    }
  }, [isLoggedIn, session.data?.isLoaded, session]);

  useEffect(() => {
    if (session.data?.user) {
      setIsLoggedIn(true);
      setUser(session.data?.user);
      if (location.pathname === '/login' || location.pathname === '/') {
        navigate(DEFAULT_PATH, { replace: true });
      }
    } else if (session.data?.isLoaded) {
      navigate('/login', { replace: true });
    }
  }, [
    session.data?.user,
    session.data?.isLoaded,
    isLoggedIn,
    location.pathname,
    navigate,
    setIsLoggedIn,
    setShowNavigationDrawer,
    setUser,
  ]);

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      {showNavigationDrawer && <NavigationDrawer />}
      <StyledAppContainer showNavigationDrawer={showNavigationDrawer}>
        <StyledRouteContainer>
          <Routes>
            <Route path="/" element={<></>} />
            <Route path="/products" element={<Product />} />
            <Route path="/projects" element={<Projects />} />
            <Route path="/projects/:id" element={<Project />} />
            <Route path="/users" element={<Users />} />
            <Route path="/users/:id" element={<User />} />
            <Route path="/login" element={<Login />} />
            <Route path="/signup" element={<SignUp />} />
            <Route path="/change-password" element={<PasswordChange />} />
            <Route path="*" element={<NoPage />} />
          </Routes>
        </StyledRouteContainer>
      </StyledAppContainer>
    </ThemeProvider>
  );
};

const StyledRouteContainer = styled.main`
  padding: 20px;
`;

const StyledAppContainer = styled.div<{ showNavigationDrawer: boolean }>`
  min-height: 100vh;
  display: grid;
  grid-template-columns: 1fr;
  margin-left: ${({ showNavigationDrawer }) => (!showNavigationDrawer ? '' : '300px')};
`;
