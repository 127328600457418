import { FC, useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { CustomInput } from '../../components/input/CustomInput';
import { DataTable } from '../../components/table/DataTable';
import { useDebounce } from '../../hooks/useDebounce';
import { useGetUsers } from '../../services/api/useUser';
import { formatDate } from '../../util/date';

export const Users: FC = () => {
  const [searchParams, setSearchParams] = useState({ email: '', role: '', id: '' });
  const debouncedSearchParams = useDebounce(searchParams, 1000);
  const { data, isLoading } = useGetUsers(debouncedSearchParams);

  return (
    <StyledUsersPage>
      <div className="filter">
        <CustomInput
          label="Email"
          value={searchParams.email}
          onChange={({ target }) => setSearchParams(cs => ({ ...cs, email: target.value }))}
        />
        <CustomInput
          label="Rolle"
          value={searchParams.role}
          onChange={({ target }) => setSearchParams(cs => ({ ...cs, role: target.value }))}
        />
        <CustomInput
          label="Id"
          value={searchParams.id}
          onChange={({ target }) => setSearchParams(cs => ({ ...cs, id: target.value }))}
        />
      </div>
      <DataTable
        data={data}
        loading={isLoading}
        columns={[
          {
            key: 'name',
            title: 'Navn',
            render: user => (
              <Link to={`/users/${user.id}`}>
                {user.firstName || user.lastName ? `${user.firstName} ${user.lastName}` : user.id}
              </Link>
            ),
          },
          {
            key: 'email',
            title: 'Email',
            render: user => user.email,
          },
          {
            key: 'role',
            title: 'Roller',
            render: user => user.roles.join(', '),
          },
          {
            key: 'updatedAt',
            title: 'Sist oppdatert',
            render: user => formatDate(user.updatedAt),
          },
          {
            key: 'createdAt',
            title: 'Opprettet',
            render: user => formatDate(user.createdAt),
          },
        ]}
        EmptyListComponent={() => <div>Ingen brukere matcher ditt søk</div>}
      />
    </StyledUsersPage>
  );
};

const StyledUsersPage = styled.div`
  .filter {
    display: flex;
    flex-direction: row;
    gap: 20px;
    margin-bottom: 20px;
  }
`;
