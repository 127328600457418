import { AxiosError } from 'axios';
import { useQuery } from 'react-query';
import { arkivetApi } from './axios';

type Message = {
  body: string;
  projectId: string;
  createdAt: string;
  updatedAt: string;
  id: string;
  type?: string;
  user: {
    userId: string;
    firstName?: string;
    lastName?: string;
    role: string;
    isProjectInitiator: boolean;
  };
};

export const useGetMessages = (id?: string) => {
  return useQuery<Message[], AxiosError>(
    ['projectId', id],
    async () => {
      const { data } = await arkivetApi.get<Message[]>(`/v1/project/${id}/message`);
      return data;
    },
    {
      enabled: !!id,
      refetchOnWindowFocus: false,
      refetchInterval: 10000,
    }
  );
};
