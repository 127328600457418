import React, { FC } from 'react';
import styled from 'styled-components';

export const NoPage: FC = () => {
  return (
    <StyledNoPage>
      <h2>Jauda, her var det ikke mye.</h2>
      <h3>Naviger til en side i venstre meny</h3>
    </StyledNoPage>
  );
};

const StyledNoPage = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
`;
