import { FC } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { useGetProjectById } from '../../services/api/useProject';
import { ProjectDetails } from './components/ProjectDetails';

export const Project: FC = () => {
  const { id } = useParams();
  const { isLoading, data } = useGetProjectById(id);
  if (isLoading) {
    return <StyledProject>Loading...</StyledProject>;
  }
  if (!data) {
    return <StyledProject>Ojjda, her var det ikke prosjekt</StyledProject>;
  }
  return (
    <StyledProject>
      <ProjectDetails data={data} />
    </StyledProject>
  );
};

const StyledProject = styled.div`
  height: 100vh;
  width: 100%;
  padding: 40px;
  gap: 20px;
`;
