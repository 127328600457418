import React, { FC } from 'react';
import styled from 'styled-components';
import { ProjectStatusType } from '../../../services/api/useProject';

export const StatusIndicator: FC<{ status: ProjectStatusType }> = ({ status }) => {
  const statusText =
    status === 'request'
      ? 'Forespørsel'
      : status === 'inProgress'
      ? 'Pågår'
      : status === 'closed'
      ? 'Ferdig'
      : status === 'lead'
      ? 'Lead'
      : 'Ingen status';
  return <StyledStatusIndicator status={status}>{statusText}</StyledStatusIndicator>;
};

const StyledStatusIndicator = styled.div<{ status: ProjectStatusType }>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 10px;
  padding: 14px 10px;
  border-radius: 20px;
  font-size: 12px;
  background-color: ${({ status, theme }) => {
    switch (status) {
      case 'request':
        return theme.color.secondary;
      case 'inProgress':
        return 'green';
      case 'closed':
        return 'red';
      case 'lead':
        return 'orange';
      default:
        return 'gray';
    }
  }};
  color: white;
`;
