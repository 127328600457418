import React, { FC } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

type NavigationItemType = {
  text: string;
  isActive: boolean;
  href?: string;
  onClick?: () => void;
};

export const NavigationItem: FC<NavigationItemType> = ({ text, href, isActive, onClick }) => {
  const navigate = useNavigate();

  return (
    <StyledNavigationItem
      isActive={isActive}
      onClick={() => {
        onClick && onClick();
        href && navigate(href);
      }}
    >
      {text}
    </StyledNavigationItem>
  );
};

const StyledNavigationItem = styled.button<{ isActive?: boolean }>`
  cursor: pointer;
  background-color: #ffffff;
  padding: 8px 0px;
  padding: 20px 0;
  border: none;
  outline: 1px solid lightgray;
  background-color: ${({ isActive, theme }) => isActive && theme.color.interactive};
  color: ${({ isActive }) => isActive && '#ffffff'};
  :hover {
    outline: 1px solid ${({ theme }) => theme.color.interactive};
  }
`;
