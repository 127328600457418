import { FC, useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { CustomDropdown } from '../../components/input/CustomDropdown';
import { CustomInput } from '../../components/input/CustomInput';
import { DataTable } from '../../components/table/DataTable';
import { useDebounce } from '../../hooks/useDebounce';
import { useGetProjects } from '../../services/api/useProject';
import { formatDate } from '../../util/date';
import { StatusIndicator } from './components/StatusIndicator';

const STATUS_FILTERS = [
  {
    value: 'request',
    text: 'Forespørsel',
  },
  {
    value: 'inProgress',
    text: 'Pågår',
  },
  {
    value: 'closed',
    text: 'Ferdig',
  },
  {
    value: 'lead',
    text: 'Lead',
  },
];

export const Projects: FC = () => {
  const [searchParams, setSearchParams] = useState({ email: '', status: '' });
  const debouncedSearchParams = useDebounce(searchParams, 1000);
  const { data, isLoading } = useGetProjects(debouncedSearchParams);

  return (
    <>
      <StyledProjectFilters>
        <CustomInput
          label="Email"
          value={searchParams.email}
          onChange={({ target }) => setSearchParams(cs => ({ ...cs, email: target.value }))}
        />
        <CustomDropdown
          onItemClicked={item => setSearchParams(cs => ({ ...cs, status: item }))}
          items={STATUS_FILTERS}
          label="Status"
        />
      </StyledProjectFilters>
      <DataTable
        data={data}
        loading={isLoading}
        columns={[
          {
            key: 'title',
            title: 'Tittel / Addresse',
            render: project => <Link to={`/projects/${project.id}`}>{project.title || project.address}</Link>,
          },
          {
            key: 'budget',
            title: 'Budsjett',
            render: project => project.budget,
          },
          {
            key: 'designerEmail',
            title: 'Designer email',
            render: project => project.designerEmail,
          },
          {
            key: 'updatedAt',
            title: 'Sist oppdatert',
            render: project => formatDate(project.updatedAt),
          },
          {
            key: 'createdAt',
            title: 'Opprettet',
            render: project => formatDate(project.createdAt),
          },
          {
            key: 'status',
            title: 'Status',
            render: project => <StatusIndicator status={project.status} />,
          },
        ]}
        EmptyListComponent={() => <div>Ingen prosjekter matcher ditt søk</div>}
      />
    </>
  );
};

const StyledProjectFilters = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  gap: 20px;
`;
