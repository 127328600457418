import { FC, useState } from 'react';
import styled from 'styled-components';
import { CustomInput } from '../components/input/CustomInput';
import { StyledButton } from '../components/styled/StyledButton';
import { useSignUp } from '../services/api/useAuth';

export const SignUp: FC = () => {
  const singUp = useSignUp();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const handleSignUp = async () => {
    if (email && password) {
      try {
        await singUp.mutateAsync({
          email,
          password,
          roles: ['admin'],
        });
      } catch (e) {}
    }
  };

  return (
    <StyledSignUp>
      <StyledSignUpForm>
        <CustomInput onChange={({ target }) => setEmail(target.value)} value={email} label="Email" />
        <CustomInput
          onChange={({ target }) => setPassword(target.value)}
          value={password}
          type="password"
          label="Password"
        />
        <StyledButton onClick={handleSignUp}>Send</StyledButton>
      </StyledSignUpForm>
    </StyledSignUp>
  );
};

const StyledSignUp = styled.div`
  width: calc(100vw - 300px);
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledSignUpForm = styled.div`
  width: 400px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  border: 1px solid lightgray;
  border-radius: 4px;
  padding: 10px;
`;
