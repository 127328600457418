import styled from 'styled-components';

export const StyledTable = styled.table`
  border-collapse: collapse;
  width: 100%;
  border: 1px solid #ddd;
  th,
  td {
    text-align: left;
    padding: 16px;
    max-width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
    * {
      white-space: nowrap;
    }
  }
  tr:nth-child(even) {
    background-color: #f2f2f2;
  }
`;
