import create from 'zustand';

export type UserType = {
  email: string;
  firstName: string;
  id: string;
  lastName: string;
  phone?: string;
  role: 'designer' | 'admin' | 'customer';
};

type AuthStoreType = {
  isLoggedIn: boolean;
  isLoaded: boolean;
  user?: UserType;
  showNavigationDrawer: boolean;
  setShowNavigationDrawer: (showNavigationDrawer: boolean) => void;
  setIsLoggedIn: (isLoggedIn: boolean) => void;
  setIsLoaded: (isLoaded: boolean) => void;
  setUser: (user: UserType) => void;
  clear: () => void;
};

export const useAuthStore = create<AuthStoreType>(set => ({
  isLoggedIn: false,
  isLoaded: false,
  showNavigationDrawer: false,
  setIsLoaded: (isLoaded: boolean) => set(() => ({ isLoaded })),
  setIsLoggedIn: (isLoggedIn: boolean) => set(() => ({ isLoggedIn })),
  setShowNavigationDrawer: (showNavigationDrawer: boolean) => set(() => ({ showNavigationDrawer })),
  user: undefined,
  setUser: (user: UserType) => set(() => ({ user, isLoggedIn: true, loaded: true, showNavigationDrawer: true })),
  clear: () => {
    set(() => ({ user: undefined, isLoggedIn: false, isLoaded: false, showNavigationDrawer: false }));
  },
}));
