import { FC, FormEvent, useRef, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { CustomInput } from '../components/input/CustomInput';
import { StyledButton } from '../components/styled/StyledButton';
import { useLogin } from '../services/api/useAuth';
import { useAuthStore } from '../services/stores/useAuthStore';

const useQuery = () => new URLSearchParams(useLocation().search);

export const Login: FC = () => {
  const emailRef = useRef<HTMLInputElement | null>(null);
  const passwordRef = useRef<HTMLInputElement | null>(null);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const { setIsLoggedIn, setShowNavigationDrawer, setIsLoaded } = useAuthStore();
  const login = useLogin();
  const navigate = useNavigate();
  const query = useQuery();

  const handleLogin = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (emailRef.current) {
      try {
        const loggedIn = await login.mutateAsync({
          email: emailRef.current.value,
          password: passwordRef.current!.value,
        });

        if (loggedIn.id) {
          setIsLoaded(true);
          setIsLoggedIn(true);
          setShowNavigationDrawer(true);
        }

        const returnUrl = query.get('returnTo');
        if (returnUrl) {
          navigate(returnUrl);
        } else {
          navigate('/projects');
        }
      } catch (err: any) {
        setErrorMessage(err.message);
      }
    }
  };

  return (
    <StyledLogin>
      <StyledLoginForm onSubmit={e => handleLogin(e)}>
        <CustomInput type="email" label="Email" inputRef={emailRef} />
        <CustomInput type="password" label="Passord" inputRef={passwordRef} />
        <StyledButton type="submit">Login</StyledButton>
        {errorMessage && (
          <p
            style={{
              color: 'red',
            }}
          >
            {errorMessage}
          </p>
        )}
      </StyledLoginForm>
    </StyledLogin>
  );
};

const StyledLogin = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledLoginForm = styled.form`
  height: 400px;
  width: 400px;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;
