import { AxiosError } from 'axios';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { removeEmptyKeys } from '../../util/object';
import { arkivetApi } from './axios';

export type ProjectStatusType = 'request' | 'inProgress' | 'closed' | 'lead';
export type ProjectLeadFollowedStatus = 'no' | 'email' | 'phone' | 'sms' | 'rejected' | 'accepted';

export type ProjectType = {
  id: string;
  title: string;
  address: string;
  description: string;
  budget: string;
  designerEmail: string;
  customerEmail: string;
  customerId: string;
  createdAt: string;
  updatedAt: string;
  status: ProjectStatusType;
  postalCode: string;
  helpType: string;
  initialMeetingType: string;
  designerComment?: string;
  emailDefault: string;
  leadFollowedStatus?: ProjectLeadFollowedStatus;
};

export type ChatType = {};
/**
 *  useMutationTyping (success, error, input, unknown)
 */
type ParamType = {
  email?: string;
};

export const useGetProjects = (params?: ParamType) => {
  const filteredParams = removeEmptyKeys(params);

  return useQuery<ProjectType[], AxiosError>(
    ['all_projects', filteredParams],
    async () => {
      const { data } = await arkivetApi.get<ProjectType[]>('/v1/project', {
        params: filteredParams,
      });
      return data;
    },
    {
      refetchOnWindowFocus: false,
      retry: 0,
    }
  );
};

export const useGetProjectById = (id?: string) => {
  return useQuery<ProjectType, AxiosError>(
    ['project_by_id'],
    async () => {
      const { data } = await arkivetApi.get<ProjectType>(`/v1/project/${id}`);

      return data;
    },
    {
      refetchOnWindowFocus: false,
      enabled: !!id,
    }
  );
};

export const useGetProjectChat = (id?: string) => {
  return useQuery<ChatType, AxiosError>(
    ['project_chat'],
    async () => {
      const { data } = await arkivetApi.get<ChatType>(`/v1/projects/${id}/chat`);
      return data;
    },
    {
      refetchOnWindowFocus: false,
      enabled: !!id,
    }
  );
};

export const useCreateProject = () => {
  const queryClient = useQueryClient();
  return useMutation<any, AxiosError, any>(
    async project => {
      const { data } = await arkivetApi.post<any>('/v1/admin/project', project);
      return data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('all_projects');
      },
    }
  );
};

export const useUpdateProject = () => {
  const queryClient = useQueryClient();
  return useMutation<any, AxiosError, any>(
    async project => {
      const { data } = await arkivetApi.put<any>(`/v1/admin/project/${project.id}`, project);
      return data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('all_projects');
      },
    }
  );
};

export const useDeleteProject = () => {
  const queryClient = useQueryClient();
  return useMutation<any, AxiosError, any>(
    async id => {
      await arkivetApi.delete<any>(`/v1/admin/project/${id}`);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('all_projects');
      },
    }
  );
};
