import { FC, useState } from 'react';
import styled from 'styled-components';
import { CustomInput } from '../components/input/CustomInput';
import { StyledButton } from '../components/styled/StyledButton';
import { useChangePassword } from '../services/api/useAuth';
import { useAuthStore } from '../services/stores/useAuthStore';

export const PasswordChange: FC = () => {
  const { user } = useAuthStore();
  const changePassword = useChangePassword();
  const [password, setPassword] = useState('');
  return (
    <StyledPage>
      <StyledPasswordChange>
        <CustomInput label="Passord" onChange={({ target }) => setPassword(target.value)} type="password" />
        <StyledButton
          onClick={async () => {
            if (user?.email) {
              await changePassword.mutateAsync({
                email: user?.email,
                password,
              });
            }
          }}
        >
          Bytt passord
        </StyledButton>
      </StyledPasswordChange>
    </StyledPage>
  );
};

const StyledPage = styled.div`
  width: calc(100vw - 300px);
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledPasswordChange = styled.div`
  width: 400px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  border: 1px solid lightgray;
  border-radius: 4px;
  padding: 10px;
`;
