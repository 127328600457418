import { normalize } from 'polished';
import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  ${normalize}
  
  h1, a {
    color: ${({ theme }) => theme.color.secondary};
  }
`;
