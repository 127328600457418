import React, { FC } from 'react';
import styled from 'styled-components';
import { useAuthStore } from '../services/stores/useAuthStore';

export const Product: FC = () => {
  const { user } = useAuthStore();
  return (
    <StyledProduct>
      <p>Dette er produkter</p>
    </StyledProduct>
  );
};

const StyledProduct = styled.div`
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
