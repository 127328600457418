import React, { FC, useEffect, useState } from 'react';
import styled from 'styled-components';

type CustomDropdownType = {
  items: {
    text: string;
    value: string;
  }[];
  presetValue?: {
    text: string;
    value: string;
  };
  label: string;
  onItemClicked: (value: string) => void;
};

export const CustomDropdown: FC<CustomDropdownType> = ({ items, onItemClicked, presetValue, label }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState('');

  const toggling = () => setIsOpen(!isOpen);

  const onOptionClicked = (value: string, text: string) => () => {
    setSelectedOption(text);
    setIsOpen(false);
    onItemClicked(value);
  };

  useEffect(() => {
    if (presetValue) {
      setSelectedOption(presetValue.text);
    }
  }, [presetValue]);

  return (
    <DropDownContainer offsetLabel={!!selectedOption}>
      <label>{label}</label>
      <DropDownHeader onClick={toggling}>{selectedOption || presetValue?.text}</DropDownHeader>
      {isOpen && (
        <DropDownListContainer>
          <DropDownList>
            <ListItem onClick={onOptionClicked('', '')} key={''}>
              {'Ikke valgt'}
            </ListItem>
            {items.map(({ text, value }) => (
              <ListItem onClick={onOptionClicked(value, text)} key={value}>
                {text}
              </ListItem>
            ))}
          </DropDownList>
        </DropDownListContainer>
      )}
    </DropDownContainer>
  );
};

const DropDownContainer = styled.div<{
  offsetLabel: boolean;
}>`
  width: 100%;
  position: relative;
  border: 1px solid black;
  border-radius: 4px;
  cursor: pointer;
  min-height: 40px;

  label {
    pointer-events: none;
    position: absolute;
    top: ${({ offsetLabel }) => (offsetLabel ? '0%' : '50%')};
    left: 10px;
    transform: translate(0px, -50%);
    padding: 0 5px;
    background-color: white;
    border-radius: 4px;
    color: ${({ offsetLabel, theme }) => (offsetLabel ? theme.color.secondary : 'black')};
    font-size: ${({ offsetLabel }) => (offsetLabel ? '14px' : '16px')};
  }
`;

const DropDownHeader = styled('div')`
  /* margin-bottom: 0.8em; */
  padding: 13px 20px;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  font-size: 16px;
  color: #000000;
  height: 100%;
  min-height: 40px;
`;

const DropDownListContainer = styled('div')`
  position: absolute;
  z-index: 100;
  width: 100%;
`;

const DropDownList = styled('ul')`
  padding: 0;
  margin: 0;
  padding-left: 1em;
  background: #ffffff;
  border: 2px solid #e5e5e5;
  box-sizing: border-box;
  color: ${({ theme }) => theme.color.secondary};
  font-size: 1.3rem;
  font-weight: 500;
  &:first-child {
    padding-top: 0.8em;
  }
`;

const ListItem = styled('li')`
  list-style: none;
  margin-bottom: 0.8em;
  cursor: pointer;
`;
