import { FC, TextareaHTMLAttributes, useState } from 'react';
import styled from 'styled-components';

type CustomTextProps = TextareaHTMLAttributes<HTMLTextAreaElement> & {
  label: string;
  style?: any;
  border?: 'primary' | 'secondary';
};
export const CustomText: FC<CustomTextProps> = props => {
  const [inputIsFocused, setInputIsFocused] = useState<boolean>(false);
  return (
    <StyledTextWrapper border={props.border} offsetLabel={!!props.value || inputIsFocused} style={props.style}>
      <label htmlFor={props.name}>{props.label}</label>
      <StyledText
        rows={5}
        value={props.value}
        onChange={props.onChange}
        onFocus={() => setInputIsFocused(true)}
        onBlur={() => setInputIsFocused(false)}
      />
    </StyledTextWrapper>
  );
};

const StyledTextWrapper = styled.div<{
  border?: 'primary' | 'secondary';
  offsetLabel: boolean;
}>`
  position: relative;
  width: 100%;
  height: fit-content;
  border-radius: 4px;
  border: ${({ border, theme }) =>
    border === 'primary'
      ? `1px solid ${theme.color.interactive}`
      : border === 'secondary'
      ? `1px solid ${theme.color.secondary}`
      : '1px solid black'};
  label {
    position: absolute;
    top: ${({ offsetLabel }) => (offsetLabel ? '0%' : '20px')};
    color: ${({ theme, offsetLabel }) => (offsetLabel ? theme.color.interactive : 'black')};
    left: 10px;
    transform: translate(0px, -50%);
    padding: 0 5px;
    background-color: white;
    font-size: ${({ offsetLabel }) => (offsetLabel ? '14px' : '16px')};
  }
`;

const StyledText = styled.textarea`
  width: 100%;
  height: 100%;
  padding: 10px 20px;
  font-weight: 300;
  border-radius: 4px;
  font-size: 16px;
  outline: none;
  border: none;
  resize: vertical;
`;
