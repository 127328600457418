import React, { FC, InputHTMLAttributes, Ref, useState } from 'react';
import styled from 'styled-components';

type CustomInputProps = InputHTMLAttributes<HTMLInputElement> & {
  label: string;
  style?: Record<string, string>;
  border?: 'primary' | 'secondary';
  inputRef?: Ref<HTMLInputElement>;
};
export const CustomInput: FC<CustomInputProps> = props => {
  const [inputIsFocused, setInputIsFocused] = useState<boolean>(false);
  return (
    <StyledInputWrapper border={props.border} offsetLabel={!!props.value || inputIsFocused}>
      <label htmlFor={props.name}>{props.label}</label>
      <StyledInput
        {...props}
        onFocus={() => setInputIsFocused(true)}
        onBlur={() => setInputIsFocused(false)}
        ref={props.inputRef}
      />
    </StyledInputWrapper>
  );
};

const StyledInputWrapper = styled.div<{
  border?: 'primary' | 'secondary';
  offsetLabel: boolean;
}>`
  position: relative;
  width: 100%;
  height: 45px;
  border-radius: 4px;
  border: ${({ border, theme }) =>
    border === 'primary'
      ? `1px solid ${theme.color.secondary}`
      : border === 'secondary'
      ? `1px solid ${theme.color.secondary}`
      : '1px solid black'};

  label {
    position: absolute;
    top: ${({ offsetLabel }) => (offsetLabel ? '0%' : '50%')};
    left: 10px;
    transform: translate(0px, -50%);
    padding: 0 5px;
    background-color: white;
    border-radius: 4px;
    color: ${({ offsetLabel, theme }) => (offsetLabel ? theme.color.secondary : 'black')};
    font-size: ${({ offsetLabel }) => (offsetLabel ? '14px' : '16px')};
    pointer-events: none;
  }
`;

const StyledInput = styled.input`
  width: 100%;
  height: 100%;
  padding: 10px 20px;
  font-weight: 300;
  border-radius: 4px;
  font-size: 16px;
  outline: none;
  border: none;
`;
