import { AxiosError } from 'axios';
import { useMutation, useQueryClient } from 'react-query';
import { arkivetApi } from './axios';

export const useSendEmail = () => {
  const queryClient = useQueryClient();
  return useMutation<any, AxiosError, any>(
    async mailData => {
      const { data } = await arkivetApi.post<any>(`/v1/admin/booking/${mailData.id}/sendLeadNudge`, { ...mailData });
      return data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('sendnudge');
      },
    }
  );
};
