import React, { FC } from 'react';
import styled from 'styled-components';

export const LinearLoader: FC = () => {
  return (
    <StyledLinearLoader>
      <div className="indeterminate"></div>
    </StyledLinearLoader>
  );
};

const StyledLinearLoader = styled.div`
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 10px;
  background-color: ${({ theme }) => theme.color.secondary};
  border-radius: 4px;
  .indeterminate {
    position: relative;
    width: 100%;
    height: 100%;
  }

  .indeterminate:before {
    content: '';
    position: absolute;
    height: 100%;
    background-color: ${({ theme }) => theme.color.secondary};
    filter: brightness(70%);
    animation: indeterminate_animation 2s infinite ease-out;
  }

  @keyframes indeterminate_animation {
    0% {
      left: -100%;
      width: 100%;
    }
    100% {
      left: 100%;
      width: 10%;
    }
  }
`;
