import {
  useCallback,
  useState,
  createContext,
  useContext,
  FC,
  ReactNode,
} from 'react';
import { light, ThemeType } from './themes';

const THEME_STORAGE_KEY: string = 'SELECTED_THEME';

const getCurrentTheme = (): ThemeType => {
  return light;
};

const ThemeContext = createContext<{
  theme: ThemeType;
  toggleTheme: () => void;
}>({
  theme: getCurrentTheme() || light,
  toggleTheme: () => undefined,
});

export const useTheme = () => {
  const context = useContext(ThemeContext);

  const { theme, toggleTheme } = context;

  return {
    theme,
    toggleTheme,
  };
};

export const ArkivetThemeProvider: FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [theme, setTheme] = useState<ThemeType>(getCurrentTheme());

  const toggleTheme = useCallback(() => {
    setTheme(light);
    localStorage.setItem(THEME_STORAGE_KEY, 'light');
  }, []);

  return (
    <ThemeContext.Provider value={{ theme, toggleTheme }}>
      {children}
    </ThemeContext.Provider>
  );
};
