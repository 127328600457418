import { useState } from 'react';
import resolveEnv, { Env } from '../../util/env';

export const useEnv = () => {
  const [env, setEnv] = useState(resolveEnv());

  const _setEnv = (str: Env) => {
    setEnv(str);
  };

  return { env, setEnv: _setEnv, envList: Object.values(Env) };
};
